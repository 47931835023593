<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Control de Senior</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Formulario de Datos
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  @change="valida()"
                  label="Número de DNI/RUC"
                  v-model="documento"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Nombres" v-model="nombres"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Apellidos" v-model="apellidos">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Teléfono" v-model="telefono">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Correo" v-model="correo"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Provincia" v-model="provincia"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Usuario" v-model="usuario"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Contraseña" v-model="contraseña">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-switch label="Estado del Senior" v-model="status"></v-switch>
              </v-col>
              <v-col cols="6" md="6">
                <v-btn block color="error" @click="cleanData()" rounded
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col cols="6" md="6">
                <v-btn
                  block
                  v-if="statusData == 1"
                  color="success"
                  @click="addData()"
                  rounded
                  >Agregar</v-btn
                >
                <v-btn
                  block
                  v-if="statusData == 2"
                  color="success"
                  @click="updateData()"
                  rounded
                  >Actualizar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Lista de Senior
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemsSenior"
            :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template></v-data-table
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Senior",
  data: () => ({
    search: "",
    itemsPais: [],
    itemsSenior: [],
    id: "",
    documento: "",
    nombres: "",
    apellidos: "",
    telefono: "",
    correo: "",
    provincia: "",
    usuario: "",
    contraseña: "",
    status: true,
    statusData: 1,

    headers: [
      {
        text: "DNI/RUC",
        align: "start",
        value: "documento",
      },
      { text: "Nombres", value: "nombres" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Teléfono", value: "telefono" },
      { text: "Correo", value: "correo" },
      { text: "Provincia", value: "provincia" },
     /*  { text: "Usuario", value: "usuario" }, */
      { text: "Acciones", value: "actions" },
    ],
  }),
  mounted() {
    this.loadPais();
    this.loadSeniors();
  },
  methods: {
    loadPais() {
      axios.get(process.env.VUE_APP_API_URL + "pais").then((result) => {
        this.itemsPais = result.data;
      });
    },
    loadSeniors() {
      axios.get(process.env.VUE_APP_API_URL + "senior").then((result) => {
        this.itemsSenior = result.data;
      });
    },
    editItem(id) {
      axios.get(process.env.VUE_APP_API_URL + "senior/" + id).then((result) => {
        this.id = result.data[0].id;
        this.documento = result.data[0].documento;
        this.nombres = result.data[0].nombres;
        this.apellidos = result.data[0].apellidos;
        this.telefono = result.data[0].telefono;
        this.correo = result.data[0].correo;
        this.usuario = result.data[0].usuario;
        this.provincia = result.data[0].provincia;
        this.contraseña = result.data[0].contraseña;
        this.status = result.data[0].status;
        this.statusData = 2;
      });
    },
    addData() {
      let data = {
        documento: this.documento,
        nombres: this.nombres,
        apellidos: this.apellidos,
        telefono: this.telefono,
        correo: this.correo,
        provincia: this.provincia,
        usuario: this.usuario,
        contraseña: this.contraseña,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "senior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Senior registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadSeniors();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    valida() {
      if (this.usuario === "" && this.contraseña === "") {
        this.usuario = this.documento;
        this.contraseña = this.documento;
      }
    },
    updateData() {
      let data = {
        documento: this.documento,
        nombres: this.nombres,
        apellidos: this.apellidos,
        telefono: this.telefono,
        correo: this.correo,
        provincia: this.provincia,
        usuario: this.usuario,
        contraseña: this.contraseña,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "senior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Senior actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadSeniors();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, El Senior no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "senior/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Senior eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadSeniors();
                this.cleanData();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "La Senior no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.documento = "";
      this.nombres = "";
      this.apellidos = "";
      this.telefono = "";
      this.correo = "";
      this.provincia = "";
      this.contraseña = "";
      this.usuario = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
